@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.leaflet-marker-icon {
    border: none !important;
    background-color: transparent !important;
}

.leaflet-marker-icon.disabled {
    opacity: 0.1;
}

.leaflet-container {
    background-color: #ffffff !important;
}

.MuiPopover-paper {
    width: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body{
    overflow-x: hidden;
    margin: 0;
}

a {
    color: unset;
    text-decoration: none;
  }

  html {
    overflow-x: hidden;
}

::-webkit-scrollbar-thumb {
    width: 0;
    background: 'transparent';
}